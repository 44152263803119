// Externals
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Selectors
import { contactUpdateInProgress, editContactSelector, updateContactErrorsSelector, addressLookupSelector, postalAddressLookupSelector } from '../../store/selectors/contact';

// Actions
import { updateContactField,
	updateCustomerContacts,
	updateContactCopyResidentialToPostal,
	resetPendingUpdate } from '../../store/actions/contact';

// Form elements
import AddressLookup from './AddressLookup';

// Components
import FlashMessage from '../Widgets/FlashMessage';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';

// Constants
import { states } from '../../store/constants';
import config from '../../config';

// Styles
import './ContactDetails.scss';

// Utility
import { preventNonNumericInput } from '../../util/validate';
import { contactUpdateFailureMessageSelector, editContactDetailsTermsSelector } from '../../store/selectors/brand';

// Helpers
const markValid = (element) => {
	if (element) {
		element.classList.remove('invalid');
		element.classList.add('valid');
	}
};

const markInvalid = (element) => {
	if (element) {
		element.classList.add('invalid');
		element.classList.remove('valid');
	}
};

const markAddressFieldInvalid = (formRef, individualFieldClass, searchFieldClass, addressMode) => {
	if(addressMode){
		markInvalid(formRef.current.querySelector(individualFieldClass));
	} else {
		markInvalid(formRef.current.querySelector(searchFieldClass));
	}
}

const validateResidentialAddress = (formRef, contact, manualAddressMode) => {
	let isValid = true;
	// Residential AddressLine
	if (contact.ResidentialAddress.AddressLine.length < 5) {
		markAddressFieldInvalid(formRef, '.field-contact-address', '.field-contact-address-lookup', manualAddressMode)		
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-address'));
	}

	// Residential Suburb
	if (contact.ResidentialAddress.Suburb.length < 3) {
		markAddressFieldInvalid(formRef, '.field-contact-suburb', '.field-contact-address-lookup', manualAddressMode)
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-suburb'));
	}

	// Residential Postcode
	if (contact.ResidentialAddress.PostCode.toString().length !== 4 || !contact.ResidentialAddress.PostCode.toString().match(/^[0-9]{4}$/)) {
		markAddressFieldInvalid(formRef, '.field-contact-postcode', '.field-contact-address-lookup');
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postcode'));
	}

	return isValid;
}

const validatePostalAddress = (formRef, contact, manualPostalAddressMode) => {
	let isValid = true;
	// Postal AddressLine
	if (contact.PostalAddress.AddressLine.length < 5) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-address', '.field-contact-postal-address-lookup', manualPostalAddressMode);
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-address'));
	}

	// Postal Suburb
	if (contact.PostalAddress.Suburb.length < 3) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-suburb', '.field-contact-postal-address-lookup', manualPostalAddressMode);
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-suburb'));
	}

	// Postal Postcode
	if (contact.PostalAddress.CountryId === defaultCountryId &&
		(contact.PostalAddress.PostCode.toString().length !== 4 
		|| !contact.PostalAddress.PostCode.toString().match(/^[0-9]{4}$/))) {
		markAddressFieldInvalid(formRef, '.field-contact-postal-postcode', '.field-contact-postal-address-lookup', manualPostalAddressMode);
		isValid = false;
	}
	else {
		markValid(formRef.current.querySelector('.field-contact-postal-postcode'));
	}

	return isValid;
}

const defaultCountryId = config.DEFAULT_COUNTRY_ID;

// Contact Details Form
const ContactDetails = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formRef = useRef();
	const validationActive = useRef(false);
	const contact = useSelector(editContactSelector);
	const editContactDetailsTermsHtml = useSelector(editContactDetailsTermsSelector);
	const updateContactFailureMessage = useSelector(contactUpdateFailureMessageSelector);
	const showError = useSelector(updateContactErrorsSelector);
	const spinner = useSelector(contactUpdateInProgress);
	const residentialAddress = useSelector(addressLookupSelector);
	const postalAddress = useSelector(postalAddressLookupSelector);

	const [ manualAddressMode, setManualAddressMode ] = useState(false);
	const [ manualPostalAddressMode, setManualPostalAddressMode ] = useState(false);
	const [ customPostalAddress, setCustomPostalAddress ] = useState(!contact.postalSame);

	// upon unmount, clear changes to pending contact update
	useEffect(() => {
		return () => {
			dispatch(resetPendingUpdate());
		}
	}, [ dispatch ]);

	useEffect(()=>{
		if(showError){
			handleScrollToError();
		}
	}, [showError])

	useEffect(()=> {
		handleValidation();
	}, [manualAddressMode, manualPostalAddressMode]);

	const handleToggleAddressMode = () => {
		setManualAddressMode(!manualAddressMode);
	};

	const handleTogglePostalAddressMode = () => {
		setManualPostalAddressMode(!manualPostalAddressMode);
	}

	const handleAddressLookupComplete = (isResidentialSearch) => {
		if(isResidentialSearch){
			markValid(document.querySelector('.field-contact-address-lookup'));
		} else {
			markValid(document.querySelector('.field-contact-postal-address-lookup'));
		}
	};

	const handleToggleCustomPostalAddress = () => {
		if (customPostalAddress === true) {
			dispatch(updateContactCopyResidentialToPostal());
		}
		setCustomPostalAddress(!customPostalAddress);
	};

	const handleUpdateField = (e) => {
		dispatch(updateContactField({ name: e.target.name, value: e.target.value }));
	};

	const handleNumericOnlyInput = (e) => {
		preventNonNumericInput(e)
	}

	const handleScrollToInvalid = () => {
		// Scroll first invalid field into view
		const firstInvalid = formRef.current.querySelectorAll('.field.invalid');
		if (firstInvalid.length) firstInvalid[0].scrollIntoView();
	}

	const handleScrollToError = () => {
		const errorMessage = formRef.current.querySelector(".flash-message");
		if(errorMessage !== null) errorMessage.scrollIntoView();
	}

	const handleValidation = () => {
		if (!validationActive.current) return;
		let isValid = true;

		//Residential Address
		isValid = validateResidentialAddress(formRef, contact, manualAddressMode);

		if(isValid) {
			markValid(formRef.current.querySelector('.field-contact-address-lookup'));
		}

		//PostalAddress
		let isPostalValid = validatePostalAddress(formRef, contact, manualPostalAddressMode);
		if(isPostalValid){
			markValid(formRef.current.querySelector('.field-contact-postal-address-lookup'))
		}

		if(isValid){
			isValid = isPostalValid;
		}

		// Mobile Phone
		if (contact.MobilePhone.match(/^04\d{8}/)){
			markValid(formRef.current.querySelector('.field-contact-mobile'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-mobile'));
			isValid = false;
		}

		// Home Phone
		if (contact.HomePhone.match(/^0[2,3,4,7,8]\d{8}$/)){
			markValid(formRef.current.querySelector('.field-contact-landline'));
		}
		else if (contact.HomePhone.match(/^$/)) {
			markValid(formRef.current.querySelector('.field-contact-landline'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-landline'));
			isValid = false;
		}

		// Email
		if (contact.Email.match(/^$/)) {
			markValid(formRef.current.querySelector('.field-contact-email'));
		}
		else if (contact.Email.match(/^(?!.*\.+@.*)[\w!#$%&'*+/=?^`\{|}~-]((?!\.\.)[\w.!#$%&'*+/=?^`\{|}~-])*@([a-zA-Z0-9][a-zA-Z0-9-]{0,61})(\.[a-zA-Z0-9-]{2,61})(?:\.[a-zA-Z0-9-]{1,61}(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){0,3}$/)) {
			markValid(formRef.current.querySelector('.field-contact-email'));
		}
		else {
			markInvalid(formRef.current.querySelector('.field-contact-email'));
			isValid = false;
		}

		return isValid;
	};

	const handleSubmitChanges = () => {
		validationActive.current = true;
		if (handleValidation() === true) {
			dispatch(updateCustomerContacts());
		}
		else {
			handleScrollToInvalid();
		}
	}
	
	const handleCancel = () => {
		navigate('/contacts');
	}

	// Render
	return (
		<div className='form form-contact-details' ref={formRef}>
			{ showError && (
				 <FlashMessage message={updateContactFailureMessage} error={true} setHtml={true}/>
			)}
{/* 
			<div className='field field-contact-name'>
				<div className='help-label'>
					<label htmlFor='contact-name'>Contact Name</label>
					<div className='info-icon'></div>
					<div className='contact-name-tooltip' dangerouslySetInnerHTML={{ __html: contactNameChangeHelpText }}></div>
				</div>
				<div className='input-container'>
					<input name='contact-name' value={contact.FirstName + ' ' + contact.LastName } disabled	/>
					<div className='padlock-icon'></div>
				</div>
			</div> */}
				
			{ manualAddressMode === false && (<>
			
				<div className='field field-contact-address-lookup'>

					<label htmlFor='addressLookup'>Home Address</label>

					<AddressLookup address={residentialAddress} isResidential={true} onComplete={handleAddressLookupComplete} />
			
				</div>

				<button className='btn-link address-mode-button' onClick={handleToggleAddressMode}>Can't find address?</button>
			
			</>)}

			{ manualAddressMode === true && (<>
			
				<div className='field field-contact-address'>
					<label htmlFor='ResidentialAddress.AddressLine'>Home Address</label>
					<input name='ResidentialAddress.AddressLine' placeholder='Enter your street address' className='gfs-app-tl-mask' value={contact.ResidentialAddress.AddressLine} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid home address</div>
				</div>

				<div className='field field-contact-suburb'>
					<label htmlFor='ResidentialAddress.Suburb'>Town/City</label>
					<input name='ResidentialAddress.Suburb' placeholder='Town/City' className='gfs-app-tl-mask' value={contact.ResidentialAddress.Suburb} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid suburb</div>
				</div>

				<div className='field field-contact-state'>
					<label htmlFor='state'>State</label>
					<select name='ResidentialAddress.State' className='gfs-app-tl-mask' value={contact.ResidentialAddress.State} onChange={handleUpdateField} onKeyUp={handleValidation}>
						{Object.keys(states).map((stateAbbrev, index) => (
							<option key={index} value={stateAbbrev}>{states[stateAbbrev]}</option>
						))}
					</select>
				</div>

				<div className='field field-contact-postcode'>
					<label htmlFor='postcode'>Postcode</label>
					<input name='ResidentialAddress.PostCode' placeholder='Postcode' className='gfs-app-tl-mask' value={contact.ResidentialAddress.PostCode} maxLength={4} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid postcode</div>
				</div>

				<button className='address-mode-button' onClick={handleToggleAddressMode}>Search for address</button>

			</>)}
			
			<div className='field field-contact-postal-address-lookup'>

				<label htmlFor='postal-toggle'>Postal Address</label>

				<div className='field field-contact-postal-toggle'>
					<input type='checkbox' checked={!customPostalAddress} onChange={handleToggleCustomPostalAddress} />
					<span>Same as Home Address</span>
				</div>
				{ manualPostalAddressMode === false && customPostalAddress === true && <>
					<AddressLookup className={'postal-address-lookup'} address={postalAddress} isResidential={false} onComplete={handleAddressLookupComplete} />
					<button className='address-mode-button postal-address-mode-button' onClick={handleTogglePostalAddressMode}>Can't find postal address?</button>
				</> }

				{ manualPostalAddressMode === true && customPostalAddress === true && (<>
				
				{/* <div className='field field-country-selection'>
					<label htmlFor='PostalAddress.CountryId'>Country</label>
					<select name="PostalAddress.CountryId" value={contact.PostalAddress.CountryId} onChange={handleUpdateField}>
						{Object.keys(countries).map((countryId) => (
							<option value={countryId} key={countryId}>{countries[countryId]}</option>
						)) }
					</select>
				</div> */}

				<div className='field field-contact-postal-address'>
					<label htmlFor='PostalAddress.AddressLine'>Postal Address</label>
					<input name='PostalAddress.AddressLine' placeholder='Enter your street address' className='gfs-app-tl-mask' value={contact.PostalAddress.AddressLine} onChange={handleUpdateField} onKeyUp={handleValidation} />
					<div className='invalid-message'>Please enter a valid address</div>
				</div>

				<div className='field field-contact-postal-suburb'>
					<label htmlFor='PostalAddress.Suburb'>Town/City</label>
					<input name='PostalAddress.Suburb' placeholder='Town/City' className='gfs-app-tl-mask' value={contact.PostalAddress.Suburb} onChange={handleUpdateField} onKeyUp={handleValidation}  />
					<div className='invalid-message'>Please enter a valid suburb</div>
				</div>

				<div className='field field-contact-postal-state'>
					<label htmlFor='field field-contact-postal-state'>State</label>
					{contact.PostalAddress.CountryId  === defaultCountryId &&
						<select name='PostalAddress.State' className='gfs-app-tl-mask' value={contact.PostalAddress.State} onChange={handleUpdateField}>
							{Object.keys(states).map((stateAbbrev, index) => (
								<option key={index} value={stateAbbrev}>{states[stateAbbrev]}</option>
							))}
						</select>
					}

					{contact.PostalAddress.CountryId  !== defaultCountryId && 
						<input name={'PostalAddress.State'} className='gfs-app-tl-mask' value={contact.PostalAddress.State} onChange={handleUpdateField}/>
					}
				</div>

				<div className='field field-contact-postal-postcode'>
					<label htmlFor='PostalAddress.PostCode'>Postcode</label>
					{contact.PostalAddress.CountryId === defaultCountryId &&
						<>
							<input name='PostalAddress.PostCode' inputMode='numeric' placeholder='Postcode' className='gfs-app-tl-mask' maxLength={4} value={contact.PostalAddress.PostCode} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
							<div className='invalid-message'>Please enter a valid postcode</div>
						</>
					}

					{contact.PostalAddress.CountryId !== defaultCountryId && 
						<input name='PostalAddress.PostCode' inputMode='numeric' placeholder='Postcode' className='gfs-app-tl-mask' value={contact.PostalAddress.PostCode} onChange={handleUpdateField} />
					}
				</div>

				<button className='address-mode-button' onClick={handleTogglePostalAddressMode}>Search for postal address</button>
			</>)}

			</div>
			

			<div className='field field-contact-mobile'>
				<label htmlFor='MobilePhone'>Mobile Phone Number</label>
				<input name='MobilePhone' inputMode='numeric' placeholder='04XXXXXXXX' className='gfs-app-tl-mask' value={contact.MobilePhone} maxLength={10} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid mobile phone number</div>
			</div>

			<div className='field field-contact-landline'>
				<label htmlFor='HomePhone'>Landline Phone Number</label>
				<input name='HomePhone' inputMode='numeric' placeholder='02XXXXXXXX' className='gfs-app-tl-mask' value={contact.HomePhone} maxLength={10} onChange={handleUpdateField} onKeyDown={handleNumericOnlyInput} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid home phone number</div>
			</div>

			<div className='field field-contact-email'>
				<label htmlFor='Email'>Email</label>
				<input name='Email' inputMode='email' placeholder='you@domain.com' className='gfs-app-tl-mask' value={contact.Email} onChange={handleUpdateField} onKeyUp={handleValidation} />
				<div className='invalid-message'>Please enter a valid email address</div>
			</div>

			<p className='terms' dangerouslySetInnerHTML={{ __html: editContactDetailsTermsHtml }}></p>

			<button className='btn form-btn btn-rounded submit' onClick={handleSubmitChanges}>Save Contact Details <span className='chevron-r'></span></button>

			<button className='btn form-btn cancel btn-rounded submit' onClick={handleCancel}>Cancel</button>

			<br /><br />

			{ spinner && <SpinnerOverlay message='Updating your contact information...'/> }

		</div>
		
	);
};

// Exports
export default ContactDetails;
