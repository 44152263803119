// Externals
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';

// Actions
import { customerLogin, validateOTP, resendOTP, customerLoginCancel, clearErrorMessage, verifyRecaptcha, clearRecaptcha, clearResetRecaptcha } from '../../store/actions/auth';
import { dobHelpTextSelector, loginFailureMessageSelector, mobileHelpTextSelector } from '../../store/selectors/brand';

// Utils
import useHasChanged from '../../util/useHasChanged';
import useInterval from '../../util/useInterval';
import { clearInvalid, clearValid, markValid, markInvalid, handleNumericOnlyInput, dayAndMonthValidation, preventNonNumericInput } from '../../util/validate';

// Components
import OTPField from '../Forms/OTPField';
import SpinnerOverlay from '../Widgets/SpinnerOverlay';

// Styles
import './Login.scss';

// Login Form
const Login = () => {
	
	const dispatch = useDispatch();

	// Mobile Number
	const [ mobileNumber, setMobileNumber ] = useState('');
	const [ mobileNumberValid, setIsValidMobileNumber] = useState(false);

	// DOB
	const [ dob_dd, setDob_dd ] = useState('');
	const [ dob_mm, setDob_mm ] = useState('');
	const [ dob_yyyy, setDob_yyyy ] = useState('');
	const [ submitted, setSubmitted] = useState(false); //await recaptcha response

	// OTP
	const [ otp, setOtp ] = useState(['', '', '' , '', '' , '']);

	const mobileGroupRef = useRef(null);
	const mobileFieldRef = useRef(null);
	const dobGroupRef = useRef(null);
	const ddRef = useRef(null);
	const mmRef = useRef(null);
	const yyyyRef = useRef(null);
	const mobileContinueButtonRef = useRef(null);
	const otpContinueButtonRef =  useRef(null);
	const otpGroupRef = useRef(null);
	const [otp0, focusOtp0] = useFocus();
	const [otp1, focusOtp1] = useFocus();
	const [otp2, focusOtp2] = useFocus();
	const [otp3, focusOtp3] = useFocus();
	const [otp4, focusOtp4] = useFocus();
	const [otp5, focusOtp5] = useFocus();

	// ReCAPTCHA
	const recaptchaRef = React.createRef();
	const recaptchaGroupRef = useRef(null);
	const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
	const siteKey = config.SITE_KEY;
	const loginAction = 'customerportal/Login';
	

	const otpReferences = useMemo( () => { return [otp0, otp1, otp2, otp3, otp4, otp5] }, [otp0, otp1, otp2, otp3, otp4, otp5]);
	const otpFocusFunctions = [focusOtp0, focusOtp1, focusOtp2, focusOtp3, focusOtp4, focusOtp5];

	// resend OTP counter
	const [ otpCountdown, setOtpCountdown ] = useState(null);
	const [ otpInterval, setOtpInterval ] = useState(0);

	useInterval(() => {
		if (otpCountdown > 0) {
			setOtpCountdown(otpCountdown - 1);
		}
		else {
			setOtpInterval(0);
		}
	}, otpInterval);

	useEffect(() => {
		if (otpCountdown > 0) {
			setOtpInterval(1000);
		}
		else {
			setOtpInterval(0);
		}
	}, [ otpCountdown ]);

	const spinner = useSelector(state => state.auth.loading);
	const customerLoginId = useSelector(state => state.auth.customerLoginId);
	const showErrorMessage = useSelector(state => state.auth.showErrorMessage);
	const showTimeoutMessage = useSelector(state => state.auth.showTimeoutMessage);
	const showExpiredOtpMessage = useSelector(state => state.auth.showExpiredOtpMessage);
	const showErrorMessageHasChanged = useHasChanged(showErrorMessage);
	const errorMessage = useSelector(loginFailureMessageSelector);
	const mobileHelpText = useSelector(mobileHelpTextSelector);
	const dobHelpText = useSelector(dobHelpTextSelector);
	const recaptchaResponse = useSelector(state => state.auth.recaptchaResponse);
	const recaptchaReset = useSelector(state => state.auth.recaptchaReset);
	const hideRecaptchaBadge = "<style> .grecaptcha-badge { display: none!important; }</style>";
	const customErrorMessage = useSelector(state => state.auth.customErrorMessage);
	const isAccountLocked = useSelector(state => state.auth.isAccountLocked);

	useEffect(()=>{
		if (otpCountdown === null) {
			focusOtp0();
		}
	}, [customerLoginId, focusOtp0, otpCountdown]);

	// scroll to top when OTP is prompted (for mobile)
	useEffect(() => {
		if (customerLoginId) {
			window.scrollTo({ top: 0, behaviour: 'smooth'});
		}
	}, [ customerLoginId ]);

	const setValue = (e) => {
		if (e.target.name === 'mobileNumber') {
			setMobileNumber(e.target.value);
		}
		else if (e.target.name === 'dob_dd') {
			setDob_dd(e.target.value);
		}
		else if (e.target.name === 'dob_mm') {
			setDob_mm(e.target.value);
		}
		else if (e.target.name === 'dob_yyyy') {
			setDob_yyyy(e.target.value);
		}
		else if (e.target.name === 'otp') {
			setOtp(e.target.value);
		}
	};

	const handleKeyDown = (e) => {

		preventNonNumericInput(e);

		const newValue = handleNumericOnlyInput(e);

		if(e.target.name === 'mobileNumber'){
			dispatch(clearErrorMessage());
			validateMobileNumber(newValue, true);
		}

		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm' || e.target.name === 'dob_yyyy'){
			dispatch(clearErrorMessage());
			validateDob(newValue, e.target.name);
		}
		
	}

	const handleKeyUp = (e) => {
		if(e.which === 13 && mobileContinueButtonRef.current){
			var mobileValid = validateMobileNumber(mobileNumber, false)
			var dobValid = validateDob(dob_yyyy, 'dob_yyyy');
			if(e.target.name === 'mobileNumber' && mobileValid && !dobValid){
				ddRef.current.focus();
			} else if(e.target.name === 'dob_dd' && !dobValid && mmRef.current){
				mmRef.current.focus();
			} else if(e.target.name === 'dob_mm' && !dobValid && yyyyRef.current) {
				yyyyRef.current.focus();
			} else {
				mobileContinueButtonRef.current.click()
				return
			}
			
		}
	}

	const handleOnBlur = (e) => {
		if(e.target.name === 'dob_dd' || e.target.name === 'dob_mm'){
			if(e.target.value.length === 1) {
				if(e.target.name === 'dob_dd' && e.target.value !== '0'){
					setDob_dd('0' + e.target.value);
				} else if(e.target.name === 'dob_mm' && e.target.value !== '0'){
					setDob_mm('0' + e.target.value);
				}
			}
		} else if(e.target.name === 'dob_yyyy'){
			if(dobGroupRef.current && !e.target.checkValidity()){
				markInvalid(dobGroupRef)
			}
		}
	}

	const handleOTPChange = (index) => (e) => {
		const newOtpVal = otp;
		newOtpVal[index] = e.target.value;
		setOtp(newOtpVal);
		if(e.target.value.length === 1 && index !== 5){
			otpFocusFunctions[index + 1]();
		}
	}

	const handleOTPKeyDown = (index) => (e) =>{

		if(showErrorMessage || showExpiredOtpMessage){
			dispatch(clearErrorMessage());
		}

		preventNonNumericInput(e);

		if(e.target.value.length === 0 && e.which === 8 && index !== 0){
			otpFocusFunctions[index - 1]();
		}

		if(e.which === 13 && otpContinueButtonRef.current){
			otpContinueButtonRef.current.click();
		}

	}

	const handleDobKeyUp = (e) => {
		var exemptModifiers = ['Tab', 'Shift', 'Alt', 'Control', 'CapsLock', 'Escape', 'Delete', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];
		if(e.target.name === 'dob_dd' && e.target.value.length === 2 
			&& mmRef.current 
			&& !exemptModifiers.includes(e.key)){
			mmRef.current.focus();
		} else if (e.target.name === 'dob_mm' && e.target.value.length === 2 
			&& yyyyRef.current 
			&& !exemptModifiers.includes(e.key)){
			yyyyRef.current.focus();
		}

		handleKeyUp(e);
	}

	const handleOTPKeyUp = (e) => {
		//Enter key is handled in keydown, so we have to supress it here. Otherwise it clears the invalid state
		if(e.which !== 13){
			validateOtp(false);
		}		
	}

	const handleLoginOTP = () => {
		if(validateOtp(true)){
			dispatch(validateOTP(otp.join('')));
		}
	}

	const handleResendOTP = () => {
		dispatch(resendOTP());
		setOtpCountdown(30);
	}

	const validateMobileNumber = useCallback((value, didChange) => {
		if(!/^(04)\d{0,8}?/.test(value) && value.length >= 2){
			markInvalid(mobileGroupRef);
		} else {
			clearInvalid(mobileGroupRef);
		}

		if(/04\d{8}/.test(value)){
			if(!showErrorMessage){
				markValid(mobileGroupRef);
			} else {
				clearValid(mobileFieldRef);
			}
			if(!mobileNumberValid){
				setIsValidMobileNumber(true);
			}
			
			if(!didChange) {
				return true;
			}
		} else {
			clearValid(mobileGroupRef);
			if(!didChange){
				markInvalid(mobileGroupRef);
				return false;
			}
		}
		
	}, [mobileNumberValid, showErrorMessage]);

	const validateDob = useCallback((value, target, isSubmit) =>{
		var isValid = null;
		if((ddRef.current && ddRef.current.checkValidity()) || (target === 'dob_dd' && value)){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
	
			//Handles date being out of bounds i.e. < 1 or > 31
			if(dd !== 0 || isSubmit){
				isValid = dayAndMonthValidation(dd, null, null);
			}

			if(isValid == null && 
				((mmRef.current && mmRef.current.checkValidity()) || (target === 'dob_mm' && value)) &&
				((yyyyRef.current && yyyyRef.current.checkValidity()) || 
					(target === 'dob_yyyy' && value.length === 4) || isSubmit)){
				const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
				const yyyy = target === 'dob_yyyy' ? parseInt(value) : parseInt(dob_yyyy);

				if(mm !== 0 || isSubmit){			
					isValid = dayAndMonthValidation(dd, mm, yyyy);
				}
			}
			
		}

		if((isValid === null) && ((mmRef.current && mmRef.current.checkValidity()) || (target === 'dob_mm' && value))){
			const dd = target === 'dob_dd' ? parseInt(value) : parseInt(dob_dd);
			const mm = target === 'dob_mm' ? parseInt(value) : parseInt(dob_mm);
			if(dd !== 0 && mm !== 0 && !isSubmit){
				isValid = dayAndMonthValidation(dd, mm, null);	
			}
		}

		if(((ddRef.current && ddRef.current.value.length < 2) || (mmRef.current && mmRef.current.value.length < 2) || (yyyyRef.current && yyyyRef.current.value.length < 4)) && isSubmit){
			isValid = false;
		}

		if(dobGroupRef.current){
			if(isValid === null) {
				clearValid(dobGroupRef);
				clearInvalid(dobGroupRef);
			} else if(!isValid){
				clearValid(dobGroupRef);
				markInvalid(dobGroupRef);
			}else{
				clearInvalid(dobGroupRef);
				markValid(dobGroupRef);
			}
		}

		return isValid;
	}, [dob_dd, dob_mm, dob_yyyy])

	const validateRecaptcha = useCallback(() => {
		// Check if recaptcha was loaded
		if (recaptchaLoaded) {
			// Clear validation messages
			clearValid(recaptchaGroupRef);
			clearInvalid(recaptchaGroupRef);

			// return true if user succeeded recaptcha
			if (recaptchaResponse !== null && recaptchaResponse.success) {
				return true;
			}
		} else {
			// Return true as a fail-safe if recaptcha wasn't able to load
			// Customer should still be able to proceed to login even if recaptcha didn't load
			return true;
		}

		// Show validation if user failed recaptcha/ did not do recaptcha, as long as recaptcha checkbox was able to load
		if (recaptchaGroupRef.current) {
			markInvalid(recaptchaGroupRef);
		}

		return false;
	});

	const recaptchaOnLoad = useCallback(() => {
		setRecaptchaLoaded(true);
	});

	const recaptchaOnChangeCallback = useCallback((value) => {
		if(!recaptchaLoaded) {
			return;
		}

		if ((value !== null || value !== undefined) && ((recaptchaResponse === null || recaptchaResponse === undefined) || (recaptchaResponse && !recaptchaResponse.success)) ) {
			const params = {
				token: value,
				sitekey: siteKey,
				action: loginAction 
			}
			dispatch(verifyRecaptcha(params));
			dispatch(clearRecaptcha());

			// clear validation message as soon as recaptcha is confirmed
			clearInvalid(recaptchaGroupRef);
		}
	}, [dispatch, siteKey, recaptchaResponse, recaptchaLoaded]);

	const recaptchaOnExpiredCallback = useCallback(() => {
		dispatch(clearRecaptcha());
	}, [dispatch]);

	const handleLogin = useCallback(() => {
		const args = {
			mobileNumber: mobileNumber,
			dob: dob_yyyy + '-' + dob_mm + '-' + dob_dd
		};

		if(validateMobileNumber(mobileNumber, false) && validateDob(dob_dd, 'dob_dd', true) && validateRecaptcha()){
			dispatch(customerLogin(args));
		}
	}, [dispatch, dob_dd, dob_mm, dob_yyyy, mobileNumber, validateDob, validateMobileNumber, recaptchaResponse, recaptchaLoaded, validateRecaptcha])

	useEffect(() => {
		if(recaptchaReset && recaptchaRef && recaptchaRef.current){
			recaptchaRef.current.reset();
			dispatch(clearResetRecaptcha());
		}
	}, [dispatch, recaptchaReset, recaptchaRef])

	const validateOtp = useCallback((isSubmit) => {
		if(otpGroupRef.current && otp0.current && otp1.current && otp2.current && otp3.current &&
			otp4.current && otp5.current){
			if(!showErrorMessage && 
				!isSubmit &&
				otp0.current.value.length === 1 &&
				otp1.current.value.length === 1 &&
				otp2.current.value.length === 1 && 
				otp3.current.value.length === 1 &&
				otp4.current.value.length === 1 &&
				otp5.current.value.length === 1) {
					clearInvalid(otpGroupRef);
					markValid(otpGroupRef);

			} else if(isSubmit && (!otp0.current.checkValidity() || !otp1.current.checkValidity() || !otp2.current.checkValidity() || !otp3.current.checkValidity() ||
				!otp4.current.checkValidity() || !otp5.current.checkValidity())){
					clearValid(otpGroupRef);
					markInvalid(otpGroupRef);
					return false;
			} else if(isSubmit) {
				clearInvalid(otpGroupRef);
				markValid(otpGroupRef);
				return true;
			} else {
				clearInvalid(otpGroupRef);
				clearValid(otpGroupRef);
			}
		}

		return true;
	}, [otp0, otp1, otp2, otp3, otp4, otp5, otpGroupRef, showErrorMessage])

	useEffect(() => {
		if((showErrorMessageHasChanged && showErrorMessage) || showExpiredOtpMessage){
			otpReferences.forEach(ref => {
				if(ref.current){
					ref.current.value = '';
				}
			});
			
			setOtp(['', '', '', '', '', '']);
			validateOtp();	
			focusOtp0();
		}
	}, [showErrorMessage, showErrorMessageHasChanged, otpReferences, showExpiredOtpMessage, validateOtp]);

	useEffect(() => {
		if(showErrorMessage){
			clearValid(mobileGroupRef);
			clearValid(dobGroupRef);
			clearValid(otpGroupRef);
		}
	}, [showErrorMessage])

	const handleCancel = useCallback(() => {
		setMobileNumber('');
		setIsValidMobileNumber(false);
		setDob_dd('');
		setDob_mm('');
		setDob_yyyy('');
		dispatch(clearRecaptcha());
		dispatch(customerLoginCancel());
	}, [setMobileNumber, setIsValidMobileNumber, setDob_dd, setDob_mm, setDob_yyyy, dispatch]);

	useEffect(()=> {
		if(showExpiredOtpMessage){
			handleCancel();
		}
	}, [showExpiredOtpMessage, handleCancel])

	// Render
	return (
		<div className='form form-login'>

			{ showErrorMessage && !customErrorMessage && <p className='error' dangerouslySetInnerHTML={{ __html: errorMessage }}></p> }
			{ (!showErrorMessage && showTimeoutMessage) && <p className='error'>Your session has expired.  Please login again to continue.</p> }
			{ showExpiredOtpMessage && <p className='error'>OTP expired. Please try again.</p>}
			{ customErrorMessage && <p className='error' dangerouslySetInnerHTML={{ __html: customErrorMessage }}></p> }
			{ customerLoginId && <div dangerouslySetInnerHTML={{ __html: hideRecaptchaBadge }}></div>}
			{ !customerLoginId && !isAccountLocked && (<>

				<div ref={mobileGroupRef} className='field field-login-mobileNumber'>

					<div className='mobile-label label-container'>
						<label htmlFor='mobileNumber'>Mobile Number</label>
						<div className='info-icon'></div>
						<div className='tooltip' dangerouslySetInnerHTML={ { __html: mobileHelpText}}></div>
					</div>

					<input ref={mobileFieldRef} type='tel' className="gfs-app-tl-mask" inputMode="numeric" name='mobileNumber' value={mobileNumber} placeholder='04XXXXXXXX' maxLength='10' pattern='04\d{8}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} required />
					<span className='invalid-message'>Please enter your 10 digit phone number.</span>
				</div>
				
				{ mobileNumberValid &&
				
				<div ref={dobGroupRef} className='field field-login-dob'>
					<div className='dob-label label-container'>
						<label htmlFor='dob_dd'>Date of Birth</label>
						<div className='info-icon'></div>
						<div className='tooltip' dangerouslySetInnerHTML={ { __html: dobHelpText}}></div>
					</div>
					
					<div className='dob-fields'>
						<input ref={ddRef} type='text' className="gfs-app-tl-mask"  inputMode="numeric" name='dob_dd' aria-label='dob_dd' value={dob_dd} placeholder='DD' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
						<input ref={mmRef} type='text' className="gfs-app-tl-mask"  inputMode="numeric" name='dob_mm' aria-label='dob_mm' value={dob_mm} placeholder='MM' maxLength={2} pattern='\d{1,2}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
						<input ref={yyyyRef} type='text' className="gfs-app-tl-mask"  inputMode="numeric" name='dob_yyyy' aria-label='dob_yyyy' value={dob_yyyy} placeholder='YYYY' maxLength={4} pattern='\d{4}' onChange={setValue} onKeyDown={handleKeyDown} onKeyUp={handleDobKeyUp} onBlur={handleOnBlur} required />
					</div>
					<span className='invalid-message'>Please enter a valid date of birth.<br/>e.g DD/MM/YYYY</span>


				</div>

				}

				{ mobileNumberValid &&
				<div ref={recaptchaGroupRef} className="field field-login-recaptcha">
					<ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={recaptchaOnChangeCallback} onExpired={recaptchaOnExpiredCallback} asyncScriptOnLoad={recaptchaOnLoad} />
					<span className='invalid-message'>Please verify that you are not a robot</span>
				</div>
				}
				<button ref={mobileContinueButtonRef} className='form-btn' disabled={submitted} onClick={(e) => { !mobileNumberValid ?  validateMobileNumber(mobileNumber, false) : handleLogin()}}><>{!mobileNumberValid ? 'Continue' : 'Log In'}</><span className='chevron-r'/></button>

			</>)}

			
			{customerLoginId &&  !isAccountLocked && (<>

				<div className='field-login-otp'>

					<label htmlFor='otp'>Enter the 6-digit code you received on the following mobile phone number:
						<div className='mobileNumber'><b>{ '**** *** ' + mobileNumber.substring(7, 10)}</b></div>
					</label>

					<div ref={otpGroupRef} className='field form-login-otp'>
						<div className='otp-field-container'>
							{otp.map((value, index) => (
								<OTPField key={index} index={index} value={value} handleOTPChange={handleOTPChange} handleOTPKeyDown={handleOTPKeyDown} handleOTPKeyUp={handleOTPKeyUp} reference={otpReferences[index]} />
							))}
						</div>
						<span className='invalid-message'>Please enter your 6 digit SMS code.</span>
					</div>
					
					<div className='resend-container'>
						<p>Need another verification code? </p>
						{(otpCountdown === 0 || otpCountdown === null) && <>&nbsp;<a href="#0" className='resend-link' onClick={handleResendOTP}>Resend</a></>}
						{otpCountdown > 0 && <span>&nbsp;{otpCountdown + 's'}</span>}
					</div>
				</div>
				<div className='login-btn-container'>
					<button ref={otpContinueButtonRef} className='form-btn btn-rounded' onClick={handleLoginOTP}>Continue <span className='chevron-r'/></button>
					<button className='form-btn btn-rounded cancel' onClick={handleCancel}>Cancel</button>
				</div>
			</>)}
			
			{isAccountLocked && (
				<button className='form-btn btn-rounded' onClick={handleCancel}>Return to Log In</button>
		    )}

			{ spinner && <SpinnerOverlay message='Validating your credentials...'/> }

		</div>
	);
};

const useFocus = () =>{
	const htmlRef = useRef(null);
	const setFocus = () => { htmlRef.current && htmlRef.current.focus() }
	return [htmlRef, setFocus];
}

// Exports
export default Login;
